import lastOtpWithin from '@/utils/lastOtpWithin';
import axios from 'axios';

export default {
  data() {
    return {
      showAuthModal: false,
      showOtpModal: false,
      loading2: false,
      authEmail: '',
    };
  },
  methods: {
    async requireAuthentication(callback) {
      if (!this.$store.getters['auth/isAuthenticated'] && !lastOtpWithin(1)) {
        this.showAuthModal = true;
        this.pendingCallback = callback;
        return false;
      }
      return callback();
    },

    handleAuthModalSubmit(authForm) {
      this.loading2 = true;
      this.authEmail = authForm.prospectEmail;

      return axios.post('/lmsagent/quotations/saveProspect', authForm)
        .then((res) => {
          const clientResp = res.data;
          localStorage.setItem('clientResp', JSON.stringify(clientResp));
          return axios.post(
            `/lmsagent/quotations/generateProspectOtpRequest?prospectCode=${clientResp.prospectCode}`,
          );
        })
        .then(() => {
          this.showAuthModal = false;
          this.showOtpModal = true;
        })
        .finally(() => {
          this.loading2 = false;
        });
    },

    handleOtpSubmit(otp) {
      if (otp.length !== 6) {
        this.$toast.error('OTP must be 6 digits long');
        return;
      }

      this.loading2 = true;
      const storedClientResp = JSON.parse(localStorage.getItem('clientResp'));
      const { prospectCode } = storedClientResp;

      axios.post(`/lmsagent/quotations/verifyProspectOtpRequest?prospectCode=${prospectCode}&otp=${otp}`)
        .then((res) => {
          if (res.data.responseStatus.status === 0) {
            this.$toast.error(res.data.responseStatus.description || 'OTP verification failed');
          } else {
            this.$toast.success('OTP verified successfully!');
            localStorage.setItem('lastOtpTimestamp', Date.now().toString());
            if (this.pendingCallback) {
              this.pendingCallback();
            }
            this.showOtpModal = false;
          }
        }).catch(() => {})
        .finally(() => {
          this.loading2 = false;
        });
    },

    handleResendOTP() {
      const storedClientResp = JSON.parse(localStorage.getItem('clientResp'));
      this.loading2 = true;

      axios.post(`/lmsagent/quotations/generateProspectOtpRequest?prospectCode=${storedClientResp.prospectCode}`)
        .then(() => {
          this.$toast.success('OTP resent successfully');
        })
        .catch(() => {
          this.$toast.error('Failed to resend OTP');
        })
        .finally(() => {
          this.loading2 = false;
        });
    },
  },
};
