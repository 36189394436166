<template>
  <div class="tw-w-full tw-px-2 md:tw-px-8">
    <v-form
      class="tw-py-4"
      v-model="valid"
      ref="form"
    >
      <div class="tw-flex tw-flex-col">
        <div class="tw-flex tw-flex-col md:tw-flex-row">
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Surname</label>
            <v-text-field type="text" name="surname" id="surname" v-model="form.surname" :rules="[required('Surname')]"
              required></v-text-field>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Other Names</label>
            <v-text-field type="text" name="othernames" id="othernames" v-model="form.othernames"
              :rules="[required('Other Name(s)')]" required></v-text-field>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Email Address</label>
            <v-text-field
              type="email"
              name="email"
              id="email"
              v-model="form.email"
              :rules="[required('Email Address'), emailFormat()]"
              required
            ></v-text-field>
          </div>
        </div>
        <div class="tw-flex tw-flex-col lg:tw-flex-row">
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Date of Birth</label>
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px"
              min-width="290px">
              <template #activator="{ on, attrs }">
                <v-text-field v-model="form.dateOfBirth" persistent-hint readonly v-bind="attrs" v-on="on"
                  :rules="[required('Date of Birth')]"></v-text-field>
              </template>
              <v-date-picker id="dateOfBirth" :active-picker.sync="activePicker" v-model="form.dateOfBirth" no-title
                @input="menu = false" :max="givenYearsAgo(18)" :min="givenYearsAgo(80)" color="primary"></v-date-picker>
            </v-menu>
          </div>
          <div v-if="wealthbuilder === false" class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">At what age do you want to retire?</label>
            <v-text-field
              type="number"
              id="retirementAge"
              v-model="retirementAge"
              :rules="[minmax('Age', 50, 100)]"
              dense
              required
            ></v-text-field>
          </div>
          <div v-else class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Term in Years(10-20 years)</label>
            <v-text-field
              type="number"
              id="term"
              v-model="termsValue"
              :items="getTerms"
              :rules="[minmax('Term', 10, 20)]"
              dense
              required
            ></v-text-field>
          </div>
          <div class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
          <label class="tw-text-sm c-blue-text">Payment Frequency</label>
          <div>
          <v-combobox
          id="freqOfPayment"
          v-model="payFreq"
          :items="frequency"
          dense
          :rules="[required('Payment Frequency')]"
          ></v-combobox>
        </div>
          </div>
          </div>
        <div v-if="wealthbuilder === false"  class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">How much do you want to contribute?</label>
            <vuetify-money id="totalContribution" v-model="contribution" name="totalContribution"
              :rules="[
                required('total contribution is required'),
               minAmount('Minimum Contribution',  500, 'Ksh')
              ]"
              :options="options"
            ></vuetify-money>
          </div>
          <div  v-else class="tw-w-full md:tw-w-1/3 tw-px-2 lg:tw-px-6">
            <label class="tw-text-sm c-blue-text">Total Contribution</label>
            <vuetify-money id="totalContribution" v-model="contribution" name="totalContribution"
              :rules="[
                required('total contribution is required'),
                switch1 ? minAmount('Total Contribution',  250000, 'Ksh') : minAmount('Total Contribution',  50000, 'Ksh')
              ]"
              :options="options"
            ></vuetify-money>
            <small class="c-yellow-text tw-italic">
              <v-icon size="14" color="secondary">mdi-information-outline</v-icon>
              Single premium minimum contribution is KES. 250,000
              & Annual premium minimum contribution is Kshs. 50,000 annually.
            </small>
          </div>
       <div v-if="wealthbuilder && yearlyBreakdown">
         <v-simple-table height="300px" fixed-header>
          <template v-slot:default>
          <thead>
            <tr>
              <th
              id="yearBreakdown"
              class="text-left"
              v-for="title in titles"
              :key="title">
              {{ title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
            v-for="(sample, index) in yearlyBreakdown"
            :key="index"
            >
              <td>
               {{ sample.quoteIntrYr | formatNum}}
              </td>
              <td>
                KES {{ sample.quoteIntrOpenBal | formatNum}}
              </td>
              <td>
               {{ index === 0 || index === 1 ? 'N/A': 'KES ' + (sample.quoteIntrEarlEcashAmt | formatNum) }}
              </td>
              <td>
               KES {{ sample.quoteIntrClosingBal | formatNum }}
              </td>
              <td>
                KES {{ sample.quoteIntrClosingBalLifeCvr | formatNum}}
              </td>
            </tr>
          </tbody>
        </template>
         </v-simple-table>
         <small class="c-yellow-text tw-italic">
              <v-icon size="14" color="secondary">mdi-information-outline</v-icon>
              Kindly note that for the first two years there is  <strong>NO projected payout. </strong>
            </small>
       </div>
       <div v-if="!wealthbuilder && yearlyBreakdown">
         <v-simple-table height="300px" fixed-header>
          <template v-slot:default>
          <thead>
            <tr>
              <th
              id="yearBreakdown"
              class="text-left"
              v-for="title in IPPtitles"
              :key="title">
              {{ title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
            v-for="(sample, index) in yearlyBreakdown"
            :key="index"
            >
              <td>
               {{ sample.quoteIntrYr | formatNum}}
              </td>
              <td>
                KES {{ sample.quoteIntrOpenBal | formatNum}}
              </td>
              <td>
                KES {{ sample.quoteIntrContributionAmt | formatNum}}
              </td>
              <td>
                KES {{ sample.quoteIntrAmount | formatNum}}
              </td>
              <td>
               KES {{ sample.quoteIntrClosingBal | formatNum }}
              </td>
            </tr>
          </tbody>
        </template>
         </v-simple-table>
       </div>
        <div
          v-if="wealthbuilder == true"
          class="
            tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-my-3
            md:tw-my-8
            tw-text-center
          "
          >
            <div class="tw-w-full md:tw-w-2/3 tw-mt-0 md:tw-mt-8">
              <div
                class="
                  tw-flex tw-flex-wrap tw-items-center tw-my-3
                  md:tw-my-8
                  tw-text-center
                "
              >
                <div
                  class="
                    tw-w-full
                    md:tw-w-1/3
                    tw-mt-4
                    md:tw-mt-0
                    tw-border-r-0
                    md:tw-border-r-2
                  "
                >
                  <h3 class="c-blue-text tw-font-bold tw-text-sm">
                    Total Contribution
                  </h3>
                  <h2 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">
                    KES {{ Math.round(scanObject(quote, 'totalContribution', 0)) | formatNum }}
                  </h2>
                </div>
                <div
                  class="
                    tw-w-full
                    md:tw-w-1/3
                    tw-mt-4
                    md:tw-mt-0
                    tw-border-r-0
                    md:tw-border-r-2
                  "
                >
                  <h3 class="c-blue-text tw-font-bold tw-text-sm">
                    Life Cover
                  </h3>
                  <h2 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">
                    KES {{ Math.round(scanObject(quote, 'sumAssured', 0)) | formatNum }}
                  </h2>
                </div>
                <div
                  class="
                    tw-w-full
                    md:tw-w-1/3
                    tw-mt-4
                    md:tw-mt-0
                  "
                >
                  <h3 class="c-blue-text tw-font-bold tw-text-sm">
                    Life Cover Premium
                  </h3>
                  <h2 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">
                    KES {{ Math.round(scanObject(quote, 'premium', 0)) | formatNum }}
                  </h2>
                </div>
              </div>
            </div>
            <div class="tw-w-full md:tw-w-2/3 tw-mt-3 md:tw-mt-8">
              <div class="
                  tw-flex tw-flex-wrap tw-items-center tw-my-3
                  md:tw-my-8
                  tw-text-center
                "
              >
                <div class="
                    tw-w-full
                    md:tw-w-1/2
                    tw-mt-4
                    md:tw-mt-0
                    tw-border-r-0
                    md:tw-border-r-2
                  "
                >
                  <h3 class="c-blue-text tw-font-bold tw-text-sm">
                    Investment Amount
                  </h3>
                  <h2 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">
                    KES {{ Math.round(scanObject(quote, 'totalInvestmentAmt', 0)) | formatNum }}
                  </h2>
                </div>
                <div class="
                    tw-w-full
                    md:tw-w-1/2
                    tw-mt-4
                    md:tw-mt-0
                  "
                >
                  <h3 class="c-blue-text tw-font-bold tw-text-sm">
                    Projected Maturity Value
                  </h3>
                  <h2 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">
                    KES {{ Math.round(scanObject(quote, 'totalEMVAmt', 0)) | formatNum }}
                  </h2>
                </div>
              </div>
            </div>
        </div>
        <div
          v-if="wealthbuilder == false"
          class="
            tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-my-3
            md:tw-my-8
            tw-text-center
          "
          >
            <div class="tw-w-full md:tw-w-2/3 tw-mt-0 md:tw-mt-8">
              <div
                class="
                  tw-flex tw-flex-wrap tw-items-center tw-my-3
                  md:tw-my-8
                  tw-text-center
                "
              >
                <div
                  class="
                    tw-w-full
                    md:tw-w-1/2
                    tw-mt-4
                    md:tw-mt-0
                    tw-border-r-0
                    md:tw-border-r-2
                  "
                >
                  <h2 class="c-blue-text tw-font-bold tw-text-sm">
                    Total Contribution
                  </h2>
                  <h1 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">
                    KES {{ Math.round(scanObject(quote, 'totalContribution', 0)) | formatNum }}
                  </h1>
                </div>
                <div
                  class="
                    tw-w-full
                    md:tw-w-1/2
                    tw-mt-4
                    md:tw-mt-0
                  "
                >
                  <h3 class="c-blue-text tw-font-bold tw-text-sm">
                    Projected funds at retirement
                  </h3>
                  <h2 class="c-light-blue-text title-font tw-text-3xl md:tw-text-5xl">
                    KES {{ Math.round(scanObject(quote, 'totalEMVAmt', 0)) | formatNum }}
                  </h2>
                </div>
              </div>
            </div>
            <div class="tw-w-full md:tw-w-2/3 tw-mt-3 md:tw-mt-8">
              <div class="
                  tw-flex tw-flex-wrap tw-items-center tw-my-3
                  md:tw-my-8
                  tw-text-center
                "
              >
                <div class="
                    tw-w-full
                    md:tw-w-1/2
                    tw-mt-4
                    md:tw-mt-0
                    tw-border-r-0
                    md:tw-border-r-2
                  "
                >
                  <h3 class="warning--text tw-font-bold tw-text-sm">
                    <v-icon color="warning">mdi-information</v-icon>
                    The projected benefits are not guaranteed.
                  </h3>
                </div>
                <div class="
                    tw-w-full
                    md:tw-w-1/2
                    tw-mt-4
                    md:tw-mt-0
                  "
                >
                  <h3 class="warning--text tw-font-bold tw-text-sm">
                    <v-icon color="primary">mdi-help-circle</v-icon>
                    Disclaimer: Amount may change depending on the portfolio perfomance.
                  </h3>
                </div>
              </div>
            </div>
        </div>
        <div class="tw-flex tw-flex-wrap tw-my-3 md:tw-my-5 tw-justify-center">
          <div class="tw-space-x-2">
            <v-btn
              :loading="loading"
              :disabled="!valid" outlined color="primary" @click="calculate"
              >Calculate</v-btn
            >
            <v-btn :disabled="!valid || !quote" outlined color="primary" @click="activateMailModal"
            >Email Quote</v-btn
            >
            <v-btn
              :disabled="!valid || !quote"
              outlined
              color="primary"
              @click="downloadReport"
              :loading="downloadQuote"
            >Download PDF</v-btn
            >
          </div>
        </div>
        <div class="tw-flex tw-justify-center tw-mt-3 md:tw-mt-5">
          <v-btn
            width="300"
            :disabled="!valid || !quote"
            :loading="proceedButton"
            :to="{ name: 'AgentGuide' }"
            color="warning"
          >Proceed</v-btn
          >
        </div>
        <EmailQuote
          v-if="quoteId"
          :email="form.email"
          :docType="reportType"
          :editable="true"
          :mailDialog="mailDialog"
          :quoteId="quoteId"
          @changeDialog="updateDialog"
        />
      </div>
    </v-form>
    <AuthModal
      :show="showAuthModal"
      :loading="loading2"
      @submit="handleAuthModalSubmit"
    />
    <OtpModal
      :show="showOtpModal"
      :loading="loading2"
      :email="authEmail"
      @submit="handleOtpSubmit"
      @resend-otp="handleResendOTP"
    />
  </div>
</template>

<script>
/*eslint-disable*/
import clientQuotationMixin from '@/mixins/clientQuotationMixin';
import OtpModal from '@/components/rfq/OtpModal.vue';
import AuthModal from '@/components/rfq/AuthModal.vue';
import authenticationMixin from '@/mixins/authenticationMixin';

export default {
  name: 'ClientQuotation',
  components: {
    AuthModal,
    OtpModal,
  },
  props: ['productData', 'terms'],
  mixins: [clientQuotationMixin, authenticationMixin],
};
</script>
