<template>
  <div class="tw-w-full tw-px-2 md:tw-px-8 tw-bg-gray-100 tw-py-8">
    <back-nav to="ClientQuotation" />

    <!-- Client Details Section -->
    <v-card class="tw-mb-6 tw-shadow-md tw-rounded-lg tw-bg-white tw-px-6 tw-py-4">
      <h2 class="tw-text-xl c-blue-text tw-font-bold">Client Details</h2>
      <v-divider class="tw-mt-4 tw-mb-2"></v-divider>
      <v-row>
        <v-col cols="12" md="4">
          <p class="c-blue-text">Full Name</p>
          <p class="c-gray-text">{{ scanObject(quoteSummaryData, 'client.clientName') }}</p>
        </v-col>
        <v-col cols="12" md="4">
          <p class="c-blue-text">Email Address</p>
          <p class="c-gray-text">{{ scanObject(quoteSummaryData, 'client.clientEmail') }}</p>
        </v-col>
        <v-col cols="12" md="4">
          <p class="c-blue-text">Sum Assured (KES)</p>
          <p class="c-gray-text">{{ scanObject(quoteSummaryData, 'sumAssured') | formatNum }}</p>
        </v-col>
        <v-col cols="12" md="4">
          <p class="c-blue-text">Date of Birth</p>
          <p class="c-gray-text">
            {{ scanObject(quoteSummaryData, 'client.clientDateOfBirth') | formatToHuman }}
          </p>
        </v-col>
        <v-col cols="12" md="4">
          <p class="c-blue-text">Term in Years</p>
          <p class="c-gray-text">{{ scanObject(quoteSummaryData, 'term') }}</p>
        </v-col>
        <v-col cols="12" md="4">
          <p class="c-blue-text">Frequency</p>
          <p class="c-gray-text">
            {{ scanObject(quoteSummaryData, 'freqOfPayment') | frequencyTitles }}
          </p>
        </v-col>
      </v-row>
    </v-card>

    <!-- Proposal Details Section with Fixed Column Width -->
    <v-card class="tw-mb-6 tw-shadow-md tw-rounded-lg tw-bg-white tw-px-6 tw-py-4">
      <h2 class="tw-text-xl c-blue-text tw-font-bold">Proposal Details</h2>
      <v-divider class="tw-mt-4 tw-mb-2"></v-divider>

      <div class="tw-overflow-x-auto">
        <table class="responsive-table tw-bg-gray-50 tw-rounded-lg tw-text-right">
          <thead class="tw-bg-blue-100">
          <tr>
            <th>Premium Breakdown (KES)</th>
            <th>Premiums (KES)</th>
            <th style="text-align: right;">Sum Assured (KES)</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(benefit, i) in scanObject(quoteSummaryData, 'benefits', []).filter(
            (b) => b.quoteBenefitName !== 'Waiver Of Premium (Inbuilt)',
          )"
            :key="i"
            class="tw-border-b tw-border-gray-200 hover:tw-bg-gray-100"
          >
            <td>{{ i === 0 ? 'Main Benefit' : benefit.quoteBenefitName }}</td>
            <td><strong>{{ Math.round(benefit.quoteBenefitPrem) | formatNum }}</strong></td>
            <td style="text-align: right;"><strong>{{ benefit.quoteBenefitName === 'Waiver Of Premium' ? 0 : Math.round(benefit.quoteBenefitSA) | formatNum }}</strong></td>
          </tr>
          </tbody>
        </table>
      </div>
    </v-card>

    <!-- Premium Payable Section -->
    <v-card class="tw-mb-6 tw-shadow-md tw-rounded-lg tw-bg-white tw-px-6 tw-py-4 tw-flex tw-justify-between">
      <table class="responsive-table tw-bg-gray-50 tw-rounded-lg">
        <tbody>
        <tr>
          <th class="c-blue-text tw-font-bold">Premium Payable (KES)</th>
          <td><strong>{{ Math.round(scanObject(quoteSummaryData, 'premium', 0)) | formatNum }}</strong></td>
          <td></td> <!-- Empty column -->
        </tr>
        </tbody>
      </table>
    </v-card>
    <!-- Projected Maturity Value Section -->
    <v-card class="tw-mb-6 tw-shadow-md tw-rounded-lg tw-bg-white tw-px-6 tw-py-4">
      <h2 class="tw-text-xl c-blue-text tw-font-bold">Projected Maturity Value</h2>
      <v-divider class="tw-mt-4 tw-mb-2"></v-divider>

      <!-- Table for Projected Maturity Breakdown -->
      <table class="tw-w-full tw-bg-gray-50 tw-rounded-lg tw-overflow-hidden">
        <thead class="tw-bg-blue-100">
          <tr>
            <th class="tw-py-3 tw-px-4 tw-text-left c-blue-text">Maturity Breakdown</th>
            <th class="tw-py-3 tw-px-4 tw-text-right c-blue-text">Total Sum Assured (KES)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tw-py-3 tw-px-4">Sum Assured</td>
            <td class="tw-py-3 tw-px-4 tw-text-right">
              <strong>{{
                Math.round(scanObject(quoteSummaryData, 'sumAssured', 0)) | formatNum
              }}</strong>
            </td>
          </tr>
          <tr v-if="scanObject(quoteSummaryData, 'terminalBonus')">
            <td class="tw-py-3 tw-px-4">Terminal Bonus</td>
            <td class="tw-py-3 tw-px-4 tw-text-right">
              <strong>{{ Math.round(quoteSummaryData.terminalBonus) | formatNum }}</strong>
            </td>
          </tr>
          <tr v-if="scanObject(quoteSummaryData, 'reversionaryBonus')">
            <td class="tw-py-3 tw-px-4">Reversionary Bonus</td>
            <td class="tw-py-3 tw-px-4 tw-text-right">
              <strong>{{ Math.round(quoteSummaryData.reversionaryBonus) | formatNum }}</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <v-divider class="tw-my-2"></v-divider>

      <!-- Total Projected Maturity Value -->
      <v-row>
        <v-col cols="6">
          <p class="c-blue-text">Total Projected Maturity Value</p>
        </v-col>
        <v-col cols="6" class="tw-py-3 tw-px-7 tw-text-right">
          <strong>{{
            Math.round(scanObject(quoteSummaryData, 'totalBenefit', 0)) | formatNum
          }}</strong>
        </v-col>
      </v-row>
    </v-card>

    <!-- Action Buttons Section -->
    <div class="tw-flex tw-flex-wrap tw-my-3 tw-justify-center tw-space-x-4">
      <v-btn outlined color="primary" @click="activateMailModal">Email Quote</v-btn>
      <v-btn outlined color="primary" @click="downloadReport" :loading="downloadQuote"
        >Download</v-btn
      >
    </div>
    <div class="tw-flex tw-justify-center tw-mt-3 md:tw-mt-5">
      <v-btn width="300" :loading="loading" :to="{ name: 'AgentGuide' }" color="warning">
        Proceed
      </v-btn>
    </div>

    <EmailQuote
      :email="scanObject(quoteSummaryData, 'client.clientEmail')"
      :docType="reportType"
      :editable="true"
      :mailDialog="mailDialog"
      :quoteId="quoteId"
      @changeDialog="updateDialog"
    />
  </div>
</template>

<style lang="scss">
.tw-shadow-md {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.v-card {
  border-radius: 8px;
}

.v-divider {
  background-color: #e0e0e0;
}

table {
  border-spacing: 0;
  width: 100%;
}

th,
td {
  padding: 1rem;
}

thead th {
  font-weight: bold;
}

tbody tr:hover {
  background-color: #f0f4f8;
}
/* Common table styling */
.responsive-table {
  width: 100%; /* Ensure the table spans the available width */
  border-collapse: collapse;
  table-layout: fixed; /* Enforce fixed column widths */
}

.responsive-table th,
.responsive-table td {
  text-align: left; /* Align text to the left */
  padding: 1rem; /* Add padding for better readability */
  word-wrap: break-word; /* Wrap text for long content */
  width: 33.3%; /* Fixed percentage width for all columns */
}

/* Highlight rows when hovered */
.responsive-table tbody tr:hover {
  background-color: #f5f5f5;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .responsive-table th,
  .responsive-table td {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 768px) {
  .responsive-table th,
  .responsive-table td {
    font-size: 0.8rem; /* Further adjust font size for tablets */
  }
}
</style>

<script>
import axios from 'axios';
import download from 'downloadjs';
import BackNav from '../BackNav';
import EmailQuote from './EmailQuotation';
import { age, formatToHuman } from '@/utils/time';
import helpers from '@/utils/helpers';

export default {
  name: 'QuoteSummary',
  components: { BackNav, EmailQuote },
  props: ['quoteSummaryData'],
  data() {
    return {
      lien: false,
      mailDialog: false,
      quoteId: this.scanObject(this.quoteSummaryData, 'quoteCode'),
      downloadQuote: false,
      loading: false,
      reportType: 'quote',
    };
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'quotesummary_page_visited_by_agent', {
        event_category: 'quote summary component created',
        event_label: 'quote summary component created',
      });
    }
  },
  methods: {
    cleanDependentName(name) {
      return name.split('/')[0];
    },
    async downloadReport() {
      this.downloadQuote = true;
      const response = await axios.post(
        `/lmsagent/quotations/generateQuoteReport?Quote%20Code=${this.scanObject(
          this.quoteSummaryData,
          'quoteCode',
        )}`,
        {},
        { responseType: 'blob' },
      );
      const content = response.headers['content-type'];
      download(response.data, 'quote-summary.pdf', content);
      this.downloadQuote = false;
    },
    activateMailModal() {
      this.mailDialog = true;
    },
    updateDialog() {
      this.mailDialog = false;
    },
  },
  quoteSummaryData() {
    return {
      dialog: false,
    };
  },
  watch: {
    open(val) {
      this.dialog = val;
    },
    dialog(val) {
      if (val === false) this.$emit('rfq-modal-close');
    },
  },

  computed: {
    getAge() {
      return age(this.scanObject(this.quoteSummaryData, 'client.clientDateOfBirth'));
    },
    getSummaryTitle() {
      if (
        [2016194, 2021197, 2021200].includes(this.scanObject(this.quoteSummaryData, 'prodCode'))
      ) {
        return 'Policy Value';
      }
      return 'Projected Maturity Value';
    },
  },

  filters: {
    formatToHuman,
    formatNum(num) {
      return helpers.numberToString(num);
    },
    frequencyTitles(title) {
      const lib = {
        Q: 'Quarterly',
        M: 'Monthly',
        Y: 'Yearly',
        S: 'Semi Annually',
        Sp: 'Single Premium',
        F: 'Single Premium',
        A: 'Annually',
      };
      return lib[title];
    },
  },
};
</script>
