<template>
  <div class="tw-flex tw--mt-2 md:tw--mt-3">
    <div class="tw-w-12 tw-h-12 md:tw-w-16 md:tw-h-16  tw-flex tw-justify-center tw-items-center tw-rounded-lg c-bg-blue">
      <v-icon color="white" size="40" >{{ icon }}</v-icon>
    </div>
    <div class="tw-ml-5 tw-pt-4">
      <h2 class="tw-text-2xl md:tw-text-3xl c-blue-text title-font"><slot></slot></h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardTitle',
  props: {
    icon: {
      type: String,
      default: 'mdi-shield-car',
    },
  },
};
</script>
