<template>
  <div class="tw-flex tw-content-between tw-flex-wrap tw-py-2 md:tw-py-4">
    <v-card
    :style="imageData.length > 0 ? 'border: 1px solid black;' : 'border: 1px solid white;'"
    :color="imageData.length > 0 ? 'gray' : 'bluelight'"
      class="
        tw-w-full tw-rounded-lg
       tw-justify-center
      "
      :flat="true"
    >
      <div
        class="tw-grid tw-grid-cols-4 tw-items-center tw-space-x-12"
        v-if="imageData.length > 0"
      >
            <p class="tw-col-span-3 tw-truncate ... tw-mt-2 tw-ml-2 tw-text-lg">{{ this.file.name }}</p>
            <v-btn
      fab
      dark
      x-small
      color="error"
      @click="deleteImage"
      ><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <label
        v-else
        class="
          tw-flex tw-items-center tw-px-12 tw-justify-center
          tw-cursor-pointer
        "
      >
        <img :src="require('../../public/images/file-upload.png')"  alt="file upload"/>
        <p class="c-blue-text tw-mt-3 tw-px-3">Upload Image (.png, .jpg)</p>
        <input
          type="file"
          :id="name"
          class="tw-hidden"
          @change="previewImage"
          accept="image/*"
        />
        <v-text-field
          class="tw-hidden"
          v-model="image"
          :rules="rules"
          style="display: none"
        ></v-text-field>
      </label>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'FileUpload',
  props: {
    rules: {
      type: Array,
      required: false,
    },
    name: String,
    addedImage: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      imageData: '',
      image: '',
      allowDelete: true,
      file: {},
      result: {},
      reader: {},
      scale: 100,
      quality: 20,
    };
  },
  watch: {
    image(val) {
      this.$emit('input', val);
    },
  },

  methods: {
    previewImage(event) {
      const input = event.target;
      // get the file

      if (input.files && input.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.file = input.files[0];
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          const FileSize = this.file.size / 1024 / 1024;
          if (FileSize > 10) {
            this.$store.commit('SNACKBAR', {
              state: 'failed',
              message: 'Image greater than 10 MB',
              variant: 'error',
            });
            return;
          }

          // Make new FileReader
          this.reader = new FileReader();
          // Convert the file to base64 text
          this.reader.readAsDataURL(this.file);
          // on reader load something...
          this.reader.onload = this.fileOnLoad;
        } else {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: 'Not Valid Image',
            variant: 'error',
          });
        }
      }
    },
    /*
       Draw And Compress The Image
       @params {String} imgUrl
     */
    drawImage(imgUrl, type) {
      // Recreate Canvas Element
      const canvas = document.createElement('canvas');
      // Set Canvas Context
      const ctx = canvas.getContext('2d');
      // Create New Image
      const img = new Image();
      img.src = imgUrl;
      img.onload = () => {
        // Image Size After Scaling
        const scale = this.scale / 100;
        const width = img.width * scale;
        const height = img.height * scale;
        // Set Canvas Height And Width According to Image Size And Scale
        canvas.setAttribute('width', width);
        canvas.setAttribute('height', height);
        ctx.drawImage(img, 0, 0, width, height);
        // Quality Of Image
        const quality = this.quality ? this.quality / 100 : 1;
        // If all files have been proceed
        const base64 = canvas.toDataURL(type, quality);
        const fileName = this.result.file.name;

        const objToPass = {
          canvas,
          original: this.result,
          compressed: {
            base64,
            name: fileName,
            file: this.buildFile(base64, type),
          },
        };

        objToPass.compressed.size = `${Math.round(
          objToPass.compressed.file.size / 1000,
        )} kB`;
        objToPass.compressed.type = type;
        this.imageData = objToPass.compressed.base64;
        this.image = {
          imageBase64: this.imageData,
          imageName: fileName || this.name,
        };
      };
    },
    /*
        When The File in loaded
      */
    fileOnLoad() {
      // The File
      const { file } = this;
      // Make a fileInfo Object
      const fileInfo = {
        name: file.name,
        type: file.type,
        size: `${Math.round(file.size / 1000)} kB`,
        base64: this.reader.result,
        file,
      };
      // Push it to the state
      this.result = fileInfo;
      // DrawImage
      this.drawImage(this.result.base64, file.type);
    },
    // Convert Blob To File
    buildFile(blob, type) {
      return new Blob([blob], { type });
    },
    deleteImage() {
      this.imageData = '';
      this.image = {};
      this.file = {};
      this.result = {};
      this.reader = {};
    },
  },
};
</script>
