const lastOtpWithin = (days) => {
  // Get the last OTP timestamp from localStorage
  const lastOtpTimestamp = localStorage.getItem('lastOtpTimestamp');

  // If no timestamp exists, return false
  if (!lastOtpTimestamp) {
    return false;
  }

  // Calculate time difference in milliseconds
  const now = Date.now();
  const timeDiff = now - parseInt(lastOtpTimestamp, 10); // Added radix parameter

  // Convert days to milliseconds (days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const allowedTime = days * 24 * 60 * 60 * 1000;

  // Return true if time difference is less than allowed time
  return timeDiff <= allowedTime;
};

export default lastOtpWithin;
