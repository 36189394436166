import { mapState } from 'vuex';
import { allProducts } from '@/utils/products';

export default {
  computed: {
    ...mapState({
      productCodeState: (state) => state.quote.productCode,
      termState: (state) => state.quote.productTerms,
    }),
    currentProduct() {
      return allProducts().find((product) => product.productCode === this.productCodeState);
    },
  },
};
