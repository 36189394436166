<template>
  <div>
    <div v-if="!pageLoading">
      <div class="tw-mb-4 c-blue-text">
        <v-chip-group mandatory active-class=" primary">
          <v-chip active-class="false" :to="{ name: 'Dashboard' }"
            >Dashboard
          </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'Products' }">Products </v-chip>
          <span class="mt-2 mx-2"> > </span>
          <v-chip :to="{ name: 'AgentGuide' }">
            {{ $options.name }}
          </v-chip>
        </v-chip-group>
      </div>

      <top-card current="ProposalSummary" cover="Elimu Plan" :amount="5"
        >Kindly provide some additional details before you proceed</top-card
      >

      <div class="tw-flex tw-flex-row-reverse tw-space-x-2 my-10">
        <v-btn class="mx-2" color="primary" @click="activateMailModal"
          >Email client <v-icon class="ml-2">mdi-email-outline</v-icon>
        </v-btn>
      </div>

      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <card-title class="tw-mt-3" icon="/images/icons/elimu-b.png"
          >Proposal Summary
        </card-title>
        <back-nav to="HealthQuestionnaire" />
        <v-card-title>
          <h2 class="tw-text-md tw-font-semibold primary--text">
            Personal Details : Life Assured
          </h2>
        </v-card-title>
        <v-divider></v-divider>
        <div
          class="
            tw-w-full tw-grid
            tw-text-semibold
            sm:tw-grid-cols-1
            md:tw-grid-cols-4
            tw-gap-4
            ml-5
            py-8
          "
        >
          <p>
            <strong>Title:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientTitle }}
            </span>
          </p>
          <p><strong>Surname:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientName }}
            </span>
          </p>
          <p><strong>Other Names:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientOtherNames }}
            </span>
          </p>
          <p><strong>Date of Birth:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientDateOfBirth | formatDate }}</span
            >
          </p>
          <p><strong>Tel No.:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientTelephone }}
            </span>
          </p>
          <p><strong>Nationality:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientNationality }}
            </span>
          </p>
          <p><strong>Marital Status:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientMaritalStatus }}
            </span>
          </p>
          <p><strong>Email Address:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientEmail }}
            </span>
          </p>
          <p><strong>Name of Employer:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientEmployer }}
            </span>
          </p>
          <p><strong>Occupation:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientOccupation }}
            </span>
          </p>
          <p><strong>Nature of business:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientOccupation }}
            </span>
          </p>

          <p><strong>Residential Address:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientPhysicalAddress }}
            </span>
          </p>
          <p><strong>KRA PIN Number:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientPIN }}
            </span>
          </p>
          <p><strong>ID Number:</strong>
            <span class="primary--text">
              {{ proposalDetails.client.clientIdNumber }}
            </span>
          </p>
          <p v-if="quoteSourceOfFunds">
          Source Of Funds:
            <span class="primary--text">
              {{ this.quoteSourceOfFunds }}
            </span>
          </p>
        </div>
        <v-card-title>
          <h2 class="tw-text-md tw-font-semibold primary--text">
            File Upload : Life Assured
          </h2>
        </v-card-title>
        <v-divider></v-divider>
        <div
          class="tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 my-6 mx-4"
        >
          <div
            v-for="(clientPhoto, i) in proposalDetails.clientPhotos"
            :key="i"
            class="tw-flex tw-flex-col"
          >
            <p>{{ clientPhoto.clientPhotoType | frequencyImagesTitle }}</p>
            <v-img
              :lazy-src="clientPhoto.clientPhotoPath"
              max-height="250"
              max-width="350"
              :src="clientPhoto.clientPhotoPath"
            ></v-img>
          </div>
        </div>
        <v-card-title>
          <h2 class="tw-text-md tw-font-semibold primary--text">
            Beneficiary Details
          </h2>
        </v-card-title>
        <v-divider></v-divider>
        <div class="my-3">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th scope="col" class="text-left">Namee</th>
                  <th scope="col" class="text-left">Tel</th>
                  <th scope="col" class="text-left">ID No.</th>
                  <th scope="col" class="text-left">Percentage</th>
                  <th scope="col" class="text-left">Relationship</th>
                  <th scope="col" class="text-left">Guardian</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in beneficiariesDetails" :key="i">
                  <td>{{ item.quoBenName }}</td>
                  <td>{{ item.quoBenPerc }}</td>
                  <td>{{ item.quoBenIdNo }}</td>
                  <td>{{ item.quoBenPerc }}</td>
                  <td>{{ item.quoBenRelType }}</td>
                  <td>
                    <v-chip>
                      {{
                        item.quoBenGuardianName === 'string'
                          ? 'Adult'
                          : item.quoBenGuardianName
                      }}</v-chip
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-card-title
        v-if="proposalDetails.client.clientGoodHealth !== null">
          <h2 class="tw-text-md tw-font-semibold primary--text">
            Health Questionnaire
          </h2>
        </v-card-title>
        <v-divider></v-divider>
        <div
           v-if="proposalDetails.client.clientGoodHealth !== null"
          class="
            tw-grid
            sm:tw-grid-cols-1
            md:tw-grid-cols-3
            tw-gap-4
            py-10
            px-5
          "
        >
          <div class="tw-flex tw-flex-col">
            <h2 class="primary--text">a) Are you in good health?</h2>
            <p class="pl-5">
              <br />
              <span class="tw-font-bold py-3">{{
                proposalDetails.client.clientGoodHealth | frequencyTitles
              }}</span>
              <br />
              <br />
              <span
                v-if="proposalDetails.client.clientGoodHealth === 'N'"
                class="tw-font-bold"
              >
                Reason
              </span>
              <br />
              {{ proposalDetails.client.clientGoodHealthComments }}
            </p>
          </div>
          <div class="tw-flex tw-flex-col">
            <h2 class="primary--text">
              b) Do you have any known medical Condition?
            </h2>
            <p class="pl-5">
              <br />
              <span class="tw-font-bold py-3">{{
                proposalDetails.client.clientMedCondition | frequencyTitles
              }}</span>
              <br />
              <br />
              <span
                v-if="proposalDetails.client.clientMedCondition === 'Y'"
                class="tw-font-bold"
              >
                Reason:
              </span>
              <br />
              {{ proposalDetails.client.clientMedConditionComments }}
            </p>
          </div>
          <div class="tw-flex tw-flex-col">
            <h2 class="primary--text">
              c) Are you currently taking any medication regularly or as
              required?
            </h2>
            <p class="pl-5">
              <br />
              <span class="tw-font-bold py-3">{{
                proposalDetails.client.clientMedCheckup | frequencyTitles
              }}</span>
              <br />
              <br />
              <span
                v-if="proposalDetails.client.clientMedCheckup === 'Y'"
                class="tw-font-bold"
              >
                Reason:
              </span>
              <br />
              {{ proposalDetails.client.clientMedCheckupComments }}
            </p>
          </div>
          <div class="tw-flex tw-flex-col">
            <h2 class="primary--text">d). Height (cm)</h2>
            <p class="pl-5">{{ proposalDetails.client.clientHeight }}</p>
          </div>
          <div class="tw-flex tw-flex-col">
            <h2 class="primary--text">e). Weight (kg)</h2>
            <p class="pl-5">{{ proposalDetails.client.clientWeight }}</p>
          </div>
          <div
            v-if="proposalDetails.client.clientAdditionalComments"
            class="tw-flex tw-flex-col"
          >
            <h2 class="primary--text">
              f). Comment any other additional Information?
            </h2>
            <p class="pl-5">
              {{ proposalDetails.client.clientAdditionalComments }}
            </p>
          </div>
        </div>

        <div
          v-show="proposalDetails.sumAssured > 5000000 || age(proposalDetails.client.clientDateOfBirth) >= 50"
          class="tw-bg-yellow-100 tw-rounded-lg tw-p-2"
        >
            <span>
              <v-icon color="warning">mdi-information-outline</v-icon>
              <small class="c-yellow-text tw-px-2">
                Based on the calculations, you will be required to go for a
                medical checkup.</small
              >
            </span>
        </div>
        <v-divider></v-divider>
        <div class="container tw-w-full tw-py-3 md:tw-py-6">
          <div class="tw-flex tw-flex-wrap">
            <v-btn
              class="secondary tw-w-64 mx-auto tw-my-8"
              large
              @click="proceed"
              >Continue</v-btn
            >
          </div>
        </div>
      </v-card>
      <EmailQuote
        :email="scanObject(proposalDetails, 'client.clientEmail')"
        :docType="reportType"
        :mailDialog="mailDialog"
        :editable="false"
        :quoteId="quoteCodeState"
        @changeDialog="updateDialog"
      />
    </div>

    <div v-else class="tw-w-full tw-flex tw-justify-center">
      <v-progress-circular
        size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import download from 'downloadjs';
import CardTitle from '@/components/CardTitle';
import TopCard from '@/components/buy/TopCard';
import BackNav from '../BackNav';
import EmailQuote from '@/components/rfq/EmailQuotation';

import validations from '@/utils/validations';
import { age, formatDate, givenYearsAgo } from '@/utils/time';

export default {
  name: 'ProposalSummary',
  metaInfo: {
    title: 'Buy Cover - Proposal Summary',
  },
  components: {
    CardTitle,
    TopCard,
    EmailQuote,
    BackNav,
  },
  data() {
    return {
      date: '',
      proposalDetails: [],
      beneficiariesDetails: [],
      downloadProposal: false,
      mailDialog: false,
      ...validations,
      pageLoading: true,
      reportType: 'proposal',
    };
  },
  watch: {
    date() {
      this.form.coverFrom = this.formatDate(this.date);
    },
    menu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
  },
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'proposal_summary_page_visited_by_agent', {
        event_category: 'proposal summary component created',
        event_label: 'proposal summary component created',
      });
    }
  },
  mounted() {
    axios
      .get(
        `/lmsagent/quotations/findQuoteSummary?Quote%20Code=${this.quoteCodeState}`,
      )
      .then((res) => {
        this.proposalDetails = res.data.data;
        this.beneficiariesDetails = res.data.data.beneficiaries;
        this.pageLoading = false;
      });
  },
  computed: {
    ...mapState({
      quoteCodeState: (state) => state.quote.quoteCode,
      quoteSourceOfFunds: (state) => state.quote.quoteSourceOfFunds,
    }),
  },
  methods: {
    age,
    proceed() {
      this.$emit('to-declaration-form');
    },
    async downloadReport() {
      this.downloadProposal = true;
      const response = await axios.post(
        `/lmsagent/quotations/generateProposalReport?Quote%20Code=${this.quoteCodeState}`,
        {},
        { responseType: 'blob' },
      );
      const content = response.headers['content-type'];
      download(response.data, 'Proposal-summary.pdf', content);
      this.downloadProposal = false;
    },

    activateMailModal() {
      this.mailDialog = true;
    },
    updateDialog() {
      this.mailDialog = false;
    },

    formatDate,
    givenYearsAgo,
  },
  filters: {
    frequencyImagesTitle(id) {
      const imgTitle = {
        'ID-FRONT': 'Front: ID / Passport Number',
        'ID-BACK': 'Back: ID / Passport Number',
        'KRA-PIN': 'KRA Pin',
      };
      return imgTitle[id];
    },
    frequencyTitles(title) {
      const lib = {
        Y: 'Yes',
        N: 'No',
      };
      return lib[title];
    },
  },
};
</script>
