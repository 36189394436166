import axios from 'axios';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import QuoteSummary from '@/components/rfq/QuoteSummary';
import PaymentOptions from '@/components/buy/PaymentOptions';
import WealthClientQuotation from '@/components/rfq/wealthbuilder/Quote';
import AkibaClientQuotation from '@/components/rfq/akiba-halisi/Quote';
import ProposalSummary from '@/components/buy/ProposalSummary';
import ClientQuotation from '@/components/rfq/ClientQuotation';
import PersonalDetails from '@/components/buy/PersonalDetails';

export default {
  data: () => ({
    search: '',
    pagination: {
      pageNumber: 1,
      perPage: 20,
      totalItems: 0,
      pageCount: 0,
    },
    headers: [
      { text: 'Cover Type', value: 'quoteProductOption.optionProduct.productDesc' },
      { text: 'Quote Type', value: 'quoteProduct.prodMatApplicable' },
      { text: 'Quote Amount', value: 'quoteSumAssured' },
      { text: 'Last Updated', value: 'quoteEffectiveDate' },
      { text: 'Action', value: 'quoteCode' },
    ],
    sortBy: ['id', 'createdAt', 'quoteEffectiveDate', 'status'],
    sortDesc: [],
    sortByQuery: 'DESC',
  }),
  computed: {
    ...mapState({
      agentCode: (state) => state.quote.clientCode,
      savedQuoteStatus: (state) => state.quote.isSaved,
      productCode: (state) => state.quote.productCode,
    }),
    ...mapGetters('quote', ['savedQuoteData']),
  },
  watch: {
    'pagination.perPage': function () {
      this.fetchSavedQuote();
    },

    'pagination.pageNumber': function () {
      this.fetchSavedQuote();
    },

    search() {
      this.initiateSearch();
    },

    sortBy() {
      this.sortByQuery = '';
      if (this.sortBy[0]) {
        const sort = this.sortDesc[0] === true ? 'desc' : 'asc';
        if (this.sortBy[0] === 'id') this.sortByQuery = `id,${sort}`;
        else if (this.sortBy[0] === 'status') this.sortByQuery = `status,${sort}`;
        else if (this.sortBy[0] === 'createdAt') this.sortByQuery = `createdAt,${sort}`;
        else if (this.sortBy[0] === 'vehicleInfo.registrationNumber') this.sortByQuery = `vehicleInfo,${sort}`;
      }
      this.fetchSavedQuote();
    },
  },
  methods: {
    initiateSearch: _.debounce(function () {
      setTimeout(
        () => {
          this.fetchSavedQuote();
        },
        1000,
      );
    }, 500),

    fetchSavedQuote() {
      this.loadingTable = true;
      axios
        .get(
          `${this.fetchSavedQuoteUrl}?agent Code=${this.agentCode}&pageNo=${this.pagination.pageNumber}&pageSize=${this.pagination.perPage}
          &search=${this.search}&sort=${this.sortByQuery}`,
        )
        .then((res) => {
          this.quotes = this.scanObject(res, 'data.data.content', []);
          this.loadingTable = false;
          this.pagination.pageCount = this.scanObject(
            res,
            'data.data.totalPages',
            0,
          );
          this.pagination.totalItems = this.scanObject(
            res,
            'data.data.totalElements',
            0,
          );
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    changetoQuoteSummary(savePoint) {
      this.quoteSummaryData = this.savedQuoteData;
      if (savePoint === 'C') {
        this.current = QuoteSummary;
      } else if (savePoint === 'B') {
        this.changetoPaymentOptions();
      } else if (savePoint === 'F') {
        this.changetoProposalSummary();
      } else {
        this.$router.push('/dashboard');
      }
    },

    changetoPaymentOptions() {
      this.current = PaymentOptions;
    },

    changetoProposalSummary() {
      this.current = ProposalSummary;
    },

    checkSavedQuote(value) {
      if (this.savedQuoteStatus === true) {
        this.changetoQuoteSummary(this.savedQuoteData.savePoint);
      } else if (this.savedQuoteStatus === false && value === 'wealth') {
        this.current = WealthClientQuotation;
      } else if (this.savedQuoteStatus === false && value === 'akiba') {
        this.current = AkibaClientQuotation;
      } else if (this.savedQuoteStatus === false && value) {
        this.current = ClientQuotation;
      } else if (this.savedQuoteStatus === false) {
        this.current = PersonalDetails;
      } else {
        this.$router.push('/dashboard');
      }
    },
  },
};
