/*eslint-disable*/
import axios from 'axios';
import { mapState } from 'vuex';
import download from 'downloadjs';
import validations from '@/utils/validations';
import { age, givenYearsAgo } from '@/utils/time';
import EmailQuote from '@/components/rfq/EmailQuotation';
import helpers from '@/utils/helpers';
import authenticationMixin from './authenticationMixin';

export default {
  props: ['benefits', 'terms', 'wealthbuilder', 'productCode', 'optionCode', 'frequency'],
  mixins: [authenticationMixin],
  components: {
    EmailQuote,
  },
  data() {
    return {
      dateFormatted: '',
      menu: false,
      time: null,
      menu2: false,
      valid: false,
      loading: false,
      payFreq: null,
      contribution: null,
      retirementAge: null,
      form: {
        name: '',
        surname: '',
        othernames: '',
        email: '',
        dateOfBirth: '',
        freqOfPayment: '',
        totalContribution: '',
      },
      termsValue: '0',
      ...validations,
      options: {
        locale: 'en',
        prefix: 'KES',
        suffix: '',
        precision: 0,
      },
      switch1: null,
      quote: null,
      activePicker: null,
      mailDialog: false,
      downloadQuote: false,
      reportType: 'quote',
      quoteId: null,
      yearlyBreakdown: null,
      proceedButton: false,
      titles: ['Year', 'Projected Fund Value', 'Projected Payout on early encashment', 'Closing Balance', 'Amount payable incase of death'],
      IPPtitles: ['Year', 'Opening Balance', 'Contribution', 'Interest', 'Closing Balance'],
    };
  },
  watch: {
    menu() {
      // eslint-disable-next-line no-return-assign
      setTimeout(() => (this.activePicker = 'YEAR'));
    },
    payFreq() {
      if (this.payFreq == 'Annually') {
        this.form.freqOfPayment = 'A';
      } else if (this.payFreq == 'Semi Annually') {
        this.form.freqOfPayment = 'S';
      } else if (this.payFreq == 'Monthly') {
        this.form.freqOfPayment = 'M';
      } else if (this.payFreq == 'Quarterly') {
        this.form.freqOfPayment = 'Q';
      } else if (this.payFreq == 'Single Premium') {
        this.form.freqOfPayment = 'Sp';
      } else {
        this.form.freqOfPayment = this.payFreq;
        this.updateContribution(this.contribution);
      }
    },
    contribution() {
      this.form.totalContribution = this.contribution;
      this.updateContribution(this.contribution);
    },
  },
  computed: {
    ...mapState({
      agentCode: (state) => (state.auth.userInfo ? state.auth.userInfo.agnCode : 0),
    }),
    getTerms() {
      return this.terms?.map((i) => (i.years ? i.years : i));
    },
  },
  methods: {
    async downloadReport() {
      this.downloadQuote = true;
      const response = await axios.post(
        `/lmsagent/quotations/generateQuoteReport?Quote%20Code=${this.scanObject(this.quote, 'quoteCode')}`,
        {},
        { responseType: 'blob' },
      );
      const content = response.headers['content-type'];
      download(response.data, 'quote-summary.pdf', content);
      this.downloadQuote = false;
    },
    activateMailModal() {
      this.mailDialog = true;
    },
    updateDialog() {
      this.mailDialog = false;
    },
    updateContribution(value) {
      if (this.payFreq === 'Sp' && value < 250000) {
        this.contribution = null;
        this.switch1 = true;
      } else if (this.payFreq === 'A' && value < 5000) {
        this.contribution = null;
        this.switch1 = false;
      } else {
        this.switch1 = null;
      }
    },
    calculate() {
      this.requireAuthentication(() => {
        this.loading = true;
        this.form.name = `${this.form.surname} ${this.form.othernames}`;
        this.$store.commit('userPersonalData/updatePersonalData', this.form);

        const formData = {
          quoteCode: 0,
          quoteAgentCode: this.agentCode,
          clientCode: 0,
          prodCode: this.productCode,
          optionCode: this.optionCode,
          ...this.form,
          term: this.termsValue,
          sumAssured: 0,
          premium: 0,
          benefits: [],
          retireAge: this.retirementAge || 0,
        };

        axios.post('/lmsagent/quotations/saveQuote', formData)
          .then((res) => {
            const quotationResp = res.data;
            this.quote = quotationResp;
            this.quoteId = quotationResp.quoteCode;
            this.yearlyBreakdown = quotationResp.intrProjections;
            this.$store.commit('quote/clientInfo', quotationResp);
          }).catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      })
        . then(r =>{})
        .catch(e => this.$toast("an error occured",e));
    },
    givenYearsAgo,
    age,
  },
  filters: {
    formatNum(num) {
      return helpers.numberToString(num);
    },
  },
};
