<template>
  <div
    id="email-quotation"
    class="tw-w-full te-flex tw-justify-end tw-text-right"
  >
    <v-dialog v-model="mailDialog" max-width="530px">
      <v-card>
        <v-card-text class="mt-5">
          <card-title icon="mdi-email">Email Quote</card-title>
          <v-container class="tw-mt-2 md:tw-mt-4 mt-5">
            <v-form class="mt-5" v-model="isValid">
              <v-row
                class="tw-w-full tw-grid tw-grid-cols-1 tw-px-4 md:tw-px-8"
              >
                <v-text-field
                  :disabled="!editable"
                  :value="email"
                  name="email"
                  id="email"
                  label="Email Address"
                  type="email"
                ></v-text-field>
                <v-chip
                  v-if="!editable"
                  class="bg-gradient-blue rounded primary--text"
                >
                  <v-icon class="tw-mr-2 primary--text" small dense
                    >mdi-alert</v-icon
                  >
                  Please ensure the validity of the Client Email Address
                </v-chip>
                <v-btn
                  block
                  large
                  color="secondary"
                  class="my-3"
                  :disabled="!isValid"
                  @click="submit"
                  :loading="loading"
                >
                  Send Quote
                </v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="tw-flex tw-justify-end">
          <v-btn color="error" text @click="exitDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import CardTitle from '@/components/shared/CardTitle';
import validations from '@/utils/validations';

export default {
  name: 'EmailQuotation',
  props: {
    email: {
      type: String,
      required: true,
      default: null,
    },
    docType: {
      type: String,
      required: true,
      default: null,
    },
    quoteId: {
      type: Number,
      required: true,
      default: null,
    },
    mailDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: { CardTitle },
  data: () => ({
    form: {
      email: '',
    },
    loading: false,
    isValid: false,

    ...validations,
  }),
  created() {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'emailquote_to_client_page_visited_by_agent', {
        event_category: 'email quote to client component created',
        event_label: 'email quote to client component created',
      });
    }
  },

  methods: {
    submit() {
      this.loading = true;
      const data = {
        emailAddress: this.email,
      };
      axios
        .post(
          `/lmsagent/quotations/generateQuoteNotification?quoteCode=${this.quoteId}&emailAddress=${data.emailAddress}&reportType=${this.docType}`,
        )
        .then(() => {
          this.loading = false;
          this.$toast.success('Email successfully sent');
          this.$emit('changeDialog');
        });
    },
    exitDialog() {
      this.$emit('changeDialog');
    },
  },
};
</script>

<style>
.v-dialog {
  box-shadow: none !important;
}
</style>
