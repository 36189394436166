<template>
  <v-card class="mx-auto tw-px-4 md:tw-px-8 tw-py-6 md:tw-py-8 tw-shadow-md tw-rounded-lg">
    <h3 class="c-blue-text c-title tw-text-lg md:tw-text-2xl tw-text-center tw-font-semibold">
      <slot></slot>
      <hr class="tw-mt-4 tw-border-b-2 tw-border-gray-200" />
    </h3>

    <div class="tw-flex tw-justify-center tw-my-8 tw-flex-wrap">
      <!-- Icon and Title Section -->
      <div class="tw-flex tw-items-center tw-px-4 md:tw-px-8">
        <img :src="scanObject(currentProduct, 'icon', '/images/icons/elimu.png')" class="tw-h-12 tw-mx-1 lg:tw-h-20 tw-mr-7"
          alt="icon" />
        <h3 class="c-gray-text c-title tw-text-lg md:tw-text-2xl tw-font-medium">
          <span>{{ scanObject(currentProduct, 'title') }}</span><br />
          <span class="text--primary">APA LIFE</span>
        </h3>
      </div>

      <!-- Terms and Amount Section -->
      <div class="tw-text-center lg:tw-text-left tw-mt-5 md:tw-mt-0 tw-px-4 md:tw-px-8">
        <h4 class="c-blue-text tw-font-semibold tw-text-base">Terms</h4>
        <h3 class="c-light-blue-text tw-text-4xl md:tw-text-5xl">
          {{ termState | formatNum }}
        </h3>
      </div>
    </div>

    <!-- Stepper Progress -->
    <div v-if="!progressHidden" class="tw-w-full tw-px-2 md:tw-px-8">
      <v-stepper alt-labels>
        <v-stepper-header>
          <v-stepper-step step="1" :complete="progress > 0" :color="progress > 0 ? 'primary' : 'grey lighten-1'">
            <span >Personal Details</span>
          </v-stepper-step>

          <v-divider :color="progress > 0 ? 'primary' : 'grey lighten-1'"></v-divider>

          <v-stepper-step step="2" :complete="progress > 25" :color="progress > 25 ? 'primary' : 'grey lighten-1'">
            <span >Add Beneficiary</span>
          </v-stepper-step>

          <v-divider :color="progress > 25 ? 'primary' : 'grey lighten-1'"></v-divider>

          <v-stepper-step step="3" :complete="progress > 50" :color="progress > 50 ? 'primary' : 'grey lighten-1'">
            <span >Health Questionnaire</span>
          </v-stepper-step>

          <v-divider :color="progress > 50 ? 'primary' : 'grey lighten-1'"></v-divider>

          <v-stepper-step step="4" :complete="progress > 75" :color="progress > 75 ? 'primary' : 'grey lighten-1'">
            <span >Proposal Summary</span>
          </v-stepper-step>

          <v-divider :color="progress > 75 ? 'primary' : 'grey lighten-1'"></v-divider>

          <v-stepper-step step="5" :complete="progress === 100"
            :color="progress === 100 ? 'primary' : 'grey lighten-1'">
            <span >Make Payment</span>
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </div>
  </v-card>
</template>

<script>
import helpers from '@/utils/helpers';
import clientInfoMixins from '@/mixins/clientInfoMixins';

export default {
  name: 'TopCard',
  props: {
    current: {
      type: String,
      default: '',
    },
    amount: Number,
    cover: {
      type: String,
      default: '',
    },
    progressHidden: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [clientInfoMixins],
  computed: {
    progress() {
      if (this.current === 'PersonalDetails') {
        return 0;
      }
      if (this.current === 'Beneficiaries') {
        return 24;
      }
      if (this.current === 'HealthQuestionnaire') {
        return 49;
      }
      if (this.current === 'ProposalSummary') {
        return 74;
      }
      if (this.current === 'PaymentOptions') {
        return 80;
      }
      return 100;
    },
    coverType() {
      return this.cover;
    },
  },
  filters: {
    formatNum(num) {
      return helpers.numberToString(num);
    },
  },
};
</script>

<style lang="scss">
$stepper-header-elevation: 0;

.v-stepper {
  box-shadow: none !important;

  .v-stepper-step {
    font-weight: 500;
    color: #005bac;
  }
}
</style>
