<template>
  <div class="tw-flex">
    <div class="tw-flex tw--mt-3 md:tw--mt-4">
      <img v-if="isImage" class="tw-w-12 tw-h-12 md:tw-w-16 md:tw-h-16" :src="icon" alt="icon">
      <v-icon v-else color="white" large>{{ icon }}</v-icon>
    </div>
    <h2 class="tw-text-xl md:tw-text-2xl c-blue-text title-font"><slot></slot></h2>
  </div>
</template>

<script>
export default {
  name: 'CardTitle',
  props: {
    icon: {
      type: String,
      required: true,
    },
    isImage: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
