<!-- AuthModal.vue -->
<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card class="tw-rounded-lg">
      <v-card-title class="tw-flex tw-flex-col tw-items-center tw-bg-gray-100 tw-py-6">
        <img src="../../../public/img/logo.png" alt="Company Logo" class="tw-w-32 tw-mb-4" />
        <span class="tw-text-xl tw-font-semibold c-blue-text">Input the following to Proceed</span>
      </v-card-title>
      <v-card-text class="tw-pt-6">
        <v-form v-model="valid">
          <v-text-field
            v-model="authForm.prospectName"
            label="Name"
            outlined
            dense
            required
          ></v-text-field>
          <v-text-field
            v-model="authForm.prospectTelephone"
            label="Phone Number"
            outlined
            dense
            required
          ></v-text-field>
          <v-text-field
            v-model="authForm.prospectEmail"
            label="Email"
            outlined
            dense
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="tw-pb-6">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          :loading="loading"
          @click="submit"
          class="tw-px-8"
        >
          Submit
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AuthModal',
  props: {
    show: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      valid: false,
      authForm: {
        prospectName: '',
        prospectTelephone: '',
        prospectEmail: '',
      },
    };
  },
  methods: {
    submit() {
      this.$emit('submit', this.authForm);
    },
  },
};
</script>

<!-- OtpModal.vue -->
<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card class="tw-rounded-lg">
      <v-card-title class="tw-flex tw-flex-col tw-items-center tw-bg-gray-100 tw-py-6">
        <img src="../../../public/img/logo.png" alt="Company Logo" class="tw-w-32 tw-mb-4" />
        <span class="tw-text-xl tw-font-semibold" :style="{ color: '#0C4DA2' }">Confirm OTP</span>
      </v-card-title>
      <v-card-text class="tw-pt-6">
        <p class="text-subtitle-1 mb-4">Enter the OTP sent to {{ maskedEmail }}</p>
        <v-otp-input v-model="otp" :length="6" @finish="otpFinished"></v-otp-input>
        <div class="d-flex justify-end mb-4">
          <v-btn
            :disabled="cooldown > 0"
            variant="text"
            class="mt-2 px-0"
            @click="handleResendOTP"
            color="secondary"
          >
            {{ cooldown > 0 ? `Resend OTP in ${cooldown}s` : 'Resend OTP' }}
          </v-btn>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          size="large"
          @click="submit"
          :loading="loading"
          :disabled="otp.length !== 6"
          block
        >
          Confirm OTP
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'OtpModal',
  props: {
    show: Boolean,
    loading: Boolean,
    email: String,
  },
  data() {
    return {
      otp: '',
      cooldown: 0,
    };
  },
  computed: {
    maskedEmail() {
      if (!this.email) return '';
      const [name, domain] = this.email.split('@');
      return `${name[0]}${'*'.repeat(name.length - 2)}${name[name.length - 1]}@${domain}`;
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.otp);
    },
    otpFinished(otp) {
      this.otp = otp;
    },
    handleResendOTP() {
      if (this.cooldown > 0) return;
      this.$emit('resend-otp');
      this.startCooldown();
    },
    startCooldown() {
      this.cooldown = 30;
      const timer = setInterval(() => {
        this.cooldown -= 1;
        if (this.cooldown <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
  },
};
</script>
